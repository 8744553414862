<template>
  <v-container fill-height class="pa-0 ma-0" style="max-width: none">
    <v-row style="height: 100%; width: 100%">
      <v-col md="6" v-if="!isSmall">
        <v-container fill-height>
          <v-row>
            <v-col cols="12">
              <router-link to="/">
                <!--                <v-img-->
                <!--                  width="40vw"-->
                <!--                  src="@/assets/img/imagotipo-horizontal.png"-->
                <!--                />-->
              </router-link>
            </v-col>
            <v-col cols="12" class="subtitle my-5">
              <div>Course: {{ productId }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12" md="6" style="background-color: #3f1e4d" class="px-0">
        <v-toolbar
          flat
          absolute
          prominent
          color="#3f1e4d"
          :width="$vuetify.breakpoint.mdAndUp ? '50%' : '100%'"
          class="mx-3"
        >
          <v-container>
            <v-row justify="center">
              <v-col cols="12">
                <router-link to="/" v-if="isSmall">
                  <!--                  <v-img-->
                  <!--                    contain-->
                  <!--                    src="@/assets/img/imagotipo-horizontal-negativo.png"-->
                  <!--                  />-->
                </router-link>
                <v-btn
                  text
                  class="toolbar-item"
                  v-if="!isSmall"
                  @click="$support.show()"
                >
                  AYUDA
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
        <v-container fill-height fluid>
          <v-row justify="center" align-content="space-around">
            <v-col cols="12" class="subtitle light my-5" v-if="isSmall">
              <div>Bienvenido,</div>
              <div>te estábamos esperando</div>
            </v-col>
            <v-col cols="12" style="text-align: center">
              <v-btn
                x-large
                outlined
                class="link-button"
                color="#fee8c0"
                @click="buyCourse('skuId')"
              >
                Comprar
              </v-btn>
            </v-col>
            <v-footer absolute class="auth-footer" v-if="isSmall">
              <v-col class="text-center" cols="12">
                <v-btn text class="footer-item" @click="$support.show()">
                  AYUDA
                </v-btn>
              </v-col>
            </v-footer>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { authGetters } from "@/store/auth";

export default {
  data() {
    return {
      productId: this.$route.params.productId,
      autoBuy: this.$route.query.autoBuy,
    };
  },
  watch: {
    $route() {
      this.productId = this.$route.params.productId;
      this.autoBuy = this.$route.query.autoBuy;
    },
  },
  created() {
    if (this.autoBuy) {
      this.buyCourse(this.autoBuy);
    }
  },
  computed: {
    isSmall() {
      const breakpoint = this.$vuetify.breakpoint.name;
      return "xs" === breakpoint || "sm" === breakpoint;
    },
    userIsLogged() {
      return this.$store.getters[authGetters.userIsLogged];
    },
  },
  methods: {
    buyCourse(skuId) {
      if (!this.userIsLogged) {
        this.$router.push({
          path: "/public/auth",
          query: {
            ...this.$route.query,
            forwardTo: this.$route.path,
            autoBuy: skuId,
          },
        });
      }
      this.checkout(skuId);
    },
    checkout(skuId) {
      // console.log(`Bought course ${this.courseId} and sku ${skuId} `);
    },
  },
};
</script>

<style scoped lang="sass">
@import "../../styles/forms"

.subtitle
  font-size: calc(12px + 2vh)
  color: $purple
  text-align: center
  font-family: 'Prata', 'sans-serif'

  &.light
    font-size: calc(12px + 2vh)
    color: $yellow

.catchword
  font-size: calc(12px + 3vh)
  color: $dark-purple
  text-align: center

.toolbar-item
  font-size: calc(10px + 1vh) !important
  color: $orange !important

.auth-footer
  background-color: #3f1e4d !important

  .footer-item
    font-size: calc(10px + 1vh)
    color: $orange

.link-button .v-btn__content
  font-size: calc(10px + 1.5vh)
</style>
