<template>
  <v-container
    fill-height
    class="pa-0 ma-0 code-container cream"
    style="max-width: none"
  >
    <v-row justify="center" align-content="center">
      <v-col cols="9" sm="8" md="7" lg="6" xl="4" class="text-center">
        <v-row
          class="pa-md-16 pa-10 white"
          justify="center"
          align="center"
          v-if="!showForm"
        >
          <h2 class="code-title mb-2 my-3 my-sm-10">
            Último paso y... <br />
            ¡Listo!
          </h2>
          <p class="code-subtitle mt-2">
            Clica en el botón que encontrarás <b>dentro del correo</b> que
            acabamos de mandarte, Ó introduce el código de acceso
            <v-btn text @click="showForm = true">aquí</v-btn>
          </p>
        </v-row>
        <v-row class="pt-10" justify="center" v-if="showForm">
          <v-col cols="10" sm="8" md="6">
            <v-row class="my-3 my-sm-10">
              <code-form
                v-on:complete="onVerificationComplete"
                :auto-confirm-code="autoConfirmCode"
                :email="email"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row class="my-3 my-sm-10 code-text">
          <v-container>
            <v-row justify="center" class="my-3 footer-item">
              ¿Algún problema?
            </v-row>
            <v-row justify="center" class="my-3">
              <v-btn text @click="dialog = true" class="footer-item">
                <span>Clica aquí para recibir un <b>nuevo código</b></span>
              </v-btn>
            </v-row>
            <v-row justify="center" class="my-3">
              <v-btn text @click="$support.show()" class="footer-item">
                <span>Clica aquí si necesitas <b>ayuda</b></span>
              </v-btn>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      overlay-color="cream"
      overlay-opacity="0.7"
      max-width="800px"
    >
      <v-container
        fill-height
        class="ma-md-0 cream email-dialog"
        style="max-width: none"
      >
        <v-row justify="center" align-content="center">
          <v-col cols="9" sm="8" md="7" lg="6" xl="4">
            <v-row
              class="my-5 my-sm-10 email-form-title font-weight-bold"
              justify="center"
            >
              Introduce tu email para recibir un nuevo código de acceso
            </v-row>
            <v-row class="ma-3 ma-sm-10" justify="center">
              <email-form v-on:sent="onCodeSent" />
            </v-row>

            <v-row class="my-3 my-sm-10 pt-10" justify="center">
              <v-btn text @click="$support.show()" class="footer-item">
                <span>Clica aquí si necesitas <b>ayuda</b></span>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import CodeForm from "@/components/auth/CodeForm";
import EmailForm from "@/components/auth/EmailForm";

export default {
  name: "CodeConfirmation",
  components: { CodeForm, EmailForm },
  data() {
    return {
      autoConfirmCode: this.$route.query.code,
      dialog: false,
      email: this.$route.query.email,
      showForm: false,
    };
  },
  created() {
    if (this.$route.query.code) {
      this.showForm = true;
    }
  },
  methods: {
    onCodeSent(email) {
      this.email = email;
      this.dialog = false;
    },
    onVerificationComplete() {
      this.$route.query.code = undefined;
      this.$route.query.email = undefined;
      this.$router.push({ path: "/app/home/", query: this.$route.query });
    },
  },
};
</script>

<style lang="sass">

@mixin link()
  font-size: calc(10px + 1vh)
  color: var(--v-black-base)
  text-align: center
  text-transform: none
  font-family: Gupter, sans-serif

  .v-btn__content
    letter-spacing: 0

.code-container
  .code-title
    color: var(--v-red-base)
    font-size: calc(19px + 1.5vw)
    font-family: 'Josefin Sans', sans-serif
    text-transform: uppercase
    line-height: 1.4em

  .code-subtitle
    color: var(--v-black-base)
    font-family: 'Gupter', serif
    font-size: calc(14px + 1vh)

  .code-instructions
    @include link()

  .footer-item
    @include link()

.email-dialog .footer-item
  @include link()
</style>
