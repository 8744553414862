<template>
  <v-container fill-height class="pa-0 ma-0" style="max-width: none">
    <v-row style="height: 100%; width: 100%">
      <v-col cols="12" style="background-color: #faecdd" class="px-0">
        <v-toolbar
          flat
          absolute
          prominent
          color="cream"
          width="100%"
          class="mx-3"
        >
          <v-container>
            <v-row justify="center" class="pt-10">
              <v-col md="4" sm="6" xs="1">
                <router-link to="/">
                  <v-img contain src="./logo.png" />
                </router-link>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
        <v-container fill-height fluid>
          <v-row justify="center" align-content="space-around">
            <v-col cols="12" class="subtitle my-5">
              <div>Para entrar, introduce tu correo electrónico</div>
            </v-col>
            <v-col cols="12">
              <email-form
                v-if="!codeRequested"
                v-on:sent="onCodeSent"
                class="px-10"
              />
            </v-col>
            <v-footer absolute class="auth-footer">
              <v-col class="text-center" cols="12">
                <v-btn text class="footer-item" @click="$support.show()">
                  <span>Clica aquí si necesitas <b>ayuda</b></span>
                </v-btn>
              </v-col>
            </v-footer>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmailForm from "@/components/auth/EmailForm";

export default {
  components: { EmailForm },
  data() {
    return {
      codeRequested: false,
    };
  },
  computed: {
    isSmall() {
      const breakpoint = this.$vuetify.breakpoint.name;
      return "xs" === breakpoint || "sm" === breakpoint;
    },
  },
  methods: {
    onCodeSent(email) {
      const query = this.$route.query;
      query.email = email;
      this.$router.push({ path: "/public/code/", query });
    },
  },
};
</script>

<style lang="sass">
.subtitle
  font-size: calc(14px + 1vh)
  color: var(--v-black-base)
  text-align: center
  width: 80%
  padding: 0 10%
  font-weight: bold
  font-family: Gupter, sans-serif

.auth-footer
  background-color: var(--v-cream-base) !important
  margin-bottom: 60px

  .footer-item
    font-size: calc(10px + 1vh)
    color: var(--v-black-base)
    text-align: center
    text-transform: none
    font-family: Gupter, sans-serif

    .v-btn__content
      letter-spacing: 0 !important

.link-button .v-btn__content
  font-size: calc(10px + 1.5vh)
</style>
