<template>
  <v-container class="pa-0">
    <v-row justify="center" align-content="center">
      <v-form v-model="valid" class="form" @submit.prevent>
        <v-text-field
          outlined
          single-line
          class="form-field"
          v-model="email"
          id="email"
          append-icon="mdi-email"
          name="email"
          label="Escribe tu email"
          type="email"
          :rules="emailRules"
          color="red"
          background-color="white"
        />
      </v-form>
    </v-row>
    <v-row>
      <v-col cols="12" style="text-align: center">
        <v-btn
          large
          class="auth-button"
          color="red"
          @click="valid && sendCode()"
          :loading="loading"
        >
          Entrar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { emailRules, requiredRule } from "@/model/validationRules";
import { sendAuthCode, signup } from "@/services/authentication";

export default {
  name: "EmailForm",
  data() {
    return {
      loading: false,
      valid: false,
      email: "",
      emailRules: requiredRule.concat(emailRules),
    };
  },
  methods: {
    async sendCode() {
      try {
        this.loading = true;
        await sendAuthCode(this.email);
        this.$emit("sent", this.email);
      } catch (e) {
        if (!e) {
          this.$snackbar.error(
            "¡Ups! Error desconocido. Inténtalo otra vez o escríbenos usando el formulario de ayuda",
            { text: "AYUDA", action: this.$support.show }
          );
        } else if (e.code === "UserNotFoundException") {
          await signup(this.email);
          await this.sendCode();
        } else {
          this.$snackbar.error("Unknown error: " + e.code);
          // console.error(e);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass">

.form
  width: 100%
  max-width: 600px

  .form-field
    border-radius: 0

.auth-button
  border-radius: 0
  padding: 0 20px !important

  .v-btn__content
    color: white
    font-weight: 300
    font-family: "Josefin Sans", sans-serif
</style>
