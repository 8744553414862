<template>
  <v-form class="form" @submit.prevent>
    <v-row justify="center">
      <v-col cols="6" v-if="!loading">
        <v-text-field
          outlined
          v-model="code"
          id="code"
          name="code"
          label="Código"
          type="text"
          height="36px"
        />
      </v-col>
      <v-col cols="6">
        <v-btn
          height="56px"
          class="validate-button"
          color="brown"
          @click="checkCode(code)"
          :loading="loading"
          style="width: 100%"
        >
          Validar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { checkAuthCode } from "@/services/authentication";

export default {
  name: "CodeForm",
  props: {
    autoConfirmCode: String,
    email: String,
  },
  data() {
    return {
      code: this.autoConfirmCode,
      loading: false,
    };
  },
  created() {
    if (this.code && this.email) {
      return this.checkCode(this.code, this.email);
    }
  },
  methods: {
    async checkCode() {
      try {
        this.loading = true;
        await checkAuthCode(this.code, this.email);
        await this.$emit("complete");
      } catch (e) {
        if (!e) {
          this.$snackbar.error(
            "¡Ups! Error desconocido. Inténtalo otra vez o escríbenos usando el formulario de ayuda",
            { text: "AYUDA", action: this.$support.show }
          );
        } else if (
          e.code === "NotAuthorizedException" &&
          e.message === "Invalid session for the user."
        ) {
          this.$snackbar.error(
            "¡Error! Has tardado más de 3 minutos clicar al botón de Acceder a la Academia o introducir el código. Pero no pasa nada, vuelve a introducir tu correo electrónico e inténtalo otra vez (recuerda que tienes 3 minutos para entrar desde que te llega el correo).",
            { text: "AYUDA", action: this.$support.show }
          );
          this.dialog = true;
        } else if (
          e.code === "NotAuthorizedException" &&
          e.message === "Incorrect username or password."
        ) {
          this.$snackbar.error(
            "¡Error! Has introducido 3 veces mal el código. Pero no pasa nada, vuelve a introducir tu correo electrónico e inténtalo otra vez.",
            { text: "AYUDA", action: this.$support.show }
          );
          this.dialog = true;
        } else if (
          e.message === "Username and pool information are required."
        ) {
          this.$snackbar.error(
            "¡Ups! Tienes que pedir un nuevo codigo y vuelve a intentarlo.",
            { text: "AYUDA", action: this.$support.show }
          );
          this.dialog = true;
        } else {
          this.$snackbar.error(
            "¡Error de acceso! Comprueba el código del correo que te hemos enviado y vuelve a intentarlo”"
          );
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="sass">

.validate-button
  border-radius: 0
  height: 56px

  .v-btn__content
    color: var(--v-black-base)
    font-weight: 300
    font-family: "Josefin Sans", sans-serif
</style>
